import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.70416 4.32851L9.70324 4.32775C8.73359 3.53264 7.512 3.09634 6.26105 3.09634H1.52799C1.15287 3.09634 0.85 3.39921 0.85 3.77433C0.85 4.14945 1.15287 4.45232 1.52799 4.45232H6.26105C7.1965 4.45232 8.11404 4.77902 8.84095 5.37782C8.96527 5.48853 9.1144 5.53659 9.26871 5.53659C9.46285 5.53659 9.65736 5.45163 9.79074 5.28296C10.0303 4.99376 9.99422 4.57022 9.70416 4.32851ZM21.1476 13.2549L21.1476 13.2549V13.2499C21.1476 12.8747 20.8448 12.5719 20.4697 12.5719H15.7366C14.8456 12.5719 14.0097 12.2904 13.2999 11.7629C12.9969 11.5331 12.583 11.5957 12.3549 11.8957C12.1265 12.1963 12.1843 12.622 12.4891 12.8426C13.4297 13.5502 14.5453 13.9184 15.7272 13.9184H20.4602C20.8346 13.9184 21.1355 13.6174 21.1476 13.2549ZM1.52799 13.9184H6.26105C9.24784 13.9184 11.6721 11.4942 11.6721 8.50739C11.6721 8.13226 11.3692 7.8294 10.9941 7.8294C10.619 7.8294 10.3161 8.13226 10.3161 8.50739C10.3161 10.7439 8.49759 12.5625 6.26105 12.5625H1.52799C1.15287 12.5625 0.85 12.8653 0.85 13.2404C0.85 13.6156 1.15287 13.9184 1.52799 13.9184Z" fill="#253449" stroke="#253449" strokeWidth="0.3"/>
<path d="M10.3161 8.50739C10.3161 8.88251 10.619 9.18538 10.9941 9.18538C11.3692 9.18538 11.6721 8.88251 11.6721 8.50739C11.6721 6.27084 13.4906 4.45232 15.7272 4.45232H20.4602C20.8353 4.45232 21.1382 4.14945 21.1382 3.77433C21.1382 3.3992 20.8353 3.09634 20.4602 3.09634H15.7272C12.7404 3.09634 10.3161 5.5206 10.3161 8.50739Z" fill="#253449" stroke="#253449" strokeWidth="0.3"/>
<path d="M20.0147 4.30465L20.0147 4.30469L20.0175 4.30734C20.2624 4.54002 20.6477 4.54383 20.8868 4.30465L20.9717 4.2198L20.9717 4.21983L20.9744 4.21704C21.2071 3.97211 21.2109 3.58685 20.9717 3.34768L18.6523 1.02829L18.6524 1.02825L18.6496 1.0256C18.4046 0.792921 18.0194 0.789115 17.7802 1.02829L17.6953 1.11314L17.6953 1.11311L17.6927 1.1159C17.46 1.36083 17.4562 1.74609 17.6953 1.98526L20.0147 4.30465Z" fill="#253449" stroke="#253449" strokeWidth="0.3"/>
<path d="M20.9744 3.33154L20.9744 3.3315L20.9717 3.32878L20.8868 3.24393C20.6477 3.00475 20.2624 3.00856 20.0175 3.24124L20.0174 3.24121L20.0147 3.24393L17.6953 5.56331C17.4562 5.80249 17.46 6.18775 17.6927 6.43268L17.6926 6.43271L17.6953 6.43543L17.7802 6.52029L17.7802 6.52032L17.783 6.52297C18.0279 6.75566 18.4131 6.75946 18.6523 6.52029L20.9717 4.2009C21.2109 3.96173 21.2071 3.57647 20.9744 3.33154Z" fill="#253449" stroke="#253449" strokeWidth="0.3"/>
<path d="M17.6765 10.5793L17.6765 10.5792L17.6738 10.582C17.4411 10.827 17.4373 11.2122 17.6765 11.4514L19.9946 13.7695C20.2345 14.0204 20.6229 14.0159 20.868 13.7708L20.9529 13.6859L20.9529 13.686L20.9555 13.6832C21.1882 13.4382 21.192 13.053 20.9529 12.8138L18.6335 10.4944L18.6335 10.4944L18.6307 10.4917C18.3858 10.259 18.0005 10.2552 17.7614 10.4944L17.6765 10.5793Z" fill="#253449" stroke="#253449" strokeWidth="0.3"/>
<path d="M18.6307 15.9892L18.6308 15.9892L18.6335 15.9865L20.9529 13.6671C21.192 13.4279 21.1882 13.0427 20.9555 12.7977L20.9556 12.7977L20.9529 12.795L20.868 12.7101C20.6288 12.4709 20.2436 12.4747 19.9986 12.7074L19.9986 12.7074L19.9959 12.7101L17.6765 15.0295C17.4373 15.2687 17.4411 15.6539 17.6738 15.8989L17.6738 15.8989L17.6765 15.9016L17.7614 15.9865C18.0005 16.2256 18.3858 16.2218 18.6307 15.9892Z" fill="#253449" stroke="#253449" strokeWidth="0.3"/>
</svg>




  );
};

export default Icon;
