import React from "react";
import fa from "@glif/filecoin-address"
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";
import LinkExternal from "../../components/Link/LinkExternal";
import Flex from "../../components/Box/Flex";
import { Modal } from "../Modal";
import CopyToClipboard from "./CopyToClipboard";
import { connectorLocalStorageKey } from "./config";
import Logout from "./Logout";


interface Props {
  account: string;
  logout: () => void;
  onDismiss?: () => void;
}

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '66')

export function shorten(str: string) {
  if (str.length < 10) return str;
  return `${str.slice(0, 6)}...${str.slice(str.length - 5)}`;
}

export function getF4Address(address: string) {
  const f4Address = fa.delegatedFromEthAddress(address).toString();
  return `f${f4Address.slice(1, f4Address.length)}`;
}

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null }) => (
  <Modal title="Your wallet" onDismiss={onDismiss}>
    <Flex mb="32px">
      <Text
      fontSize="20px"
      bold
      style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "8px" }}
    >
      0x:{shorten(account)}
    </Text>&nbsp;&nbsp;&nbsp;
      <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
    </Flex>
    {/* <Flex mb="32px">
      <Text
      fontSize="20px"
      bold
      style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "8px" }}
    >
      f4:{shorten(getF4Address(account))}
    </Text>&nbsp;&nbsp;&nbsp;
      <CopyToClipboard toCopy={getF4Address(account)}>Copy Address</CopyToClipboard>
    </Flex> */}
    {/* <Flex justifyContent="center">
      <Button
        scale="sm"
        variant="secondary"
        onClick={() => {
          logout();
          window.localStorage.removeItem(connectorLocalStorageKey);
          onDismiss();
        }}
      >
        Logout
      </Button>
    </Flex> */}
    <LinkExternal small href={`https://filscan.io/address/general?address=${account}`} mr="16px">
        View on scan
      </LinkExternal>
      <br />
      <br />
    <Logout logout={logout} onDismiss={onDismiss} />
  </Modal>
);

export default AccountModal;
