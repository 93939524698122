import React from "react";
import styled from "styled-components";
import useIntl from 'hooks/useIntl';
import { isMobile } from "react-device-detect";
import Button from "../../../components/Button/Button";
import { useWalletModal } from "../../WalletModal";
import { Login } from "../../WalletModal/types";
import { WalletIcon } from "../icons";

interface Props {
  account?: string;
  login: Login;
  logout: () => void;
  isDark: boolean;
}

const UserBlock: React.FC<Props> = ({ account, login, logout, isDark }) => {
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account);
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;
  const intl = useIntl();
  return (
    <UserBlockWrapper>
      {account ? (
        <Button
          // scale="sm"
          style={{ minWidth: isMobile ? '105px' : '55px', height: '39px', fontSize: isMobile ? '12px' : '14px',background: isDark?'#3F4552':'#FFFFFF',color: isDark?'#FFFFFF':'#0A0E13'}}
          // variant="tertiary"
          onClick={() => {
            onPresentAccountModal();
          }}
        >
          {/* {accountEllipsis} */}
          Wallet
        </Button>
      ) : (
        <Button
          style={{ minWidth: isMobile ? '165px' : '185px', height: '39px', fontSize: isMobile ? '12px' : '14px',background: isDark?'#3F4552':'#FFFFFF',color: isDark?'#FFFFFF':'#0A0E13' }}
          scale="sm"
          onClick={() => {
            onPresentConnectModal();
          }}
        >
          <WalletIcon style={{ marginRight: '5px' }}/>
          { intl('menu.connect')}
        </Button>
      )}
    </UserBlockWrapper>
  );
};

export default React.memo(UserBlock,(prevProps, nextProps) => prevProps.account === nextProps.account && prevProps.isDark === nextProps.isDark);

const UserBlockWrapper = styled.div`
// margin-right: 20px;
`