import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5689 4.65C10.5689 3.47974 9.78117 2.48978 8.70801 2.18261V0.557813C8.70801 0.167157 8.39085 -0.15 8.0002 -0.15C7.60954 -0.15 7.29238 0.167157 7.29238 0.557813V2.1812C6.21932 2.48975 5.43145 3.47963 5.43145 4.65C5.43145 5.82016 6.21912 6.81165 7.29238 7.11895V15.4406C7.29238 15.8313 7.60954 16.1484 8.0002 16.1484C8.39085 16.1484 8.70801 15.8313 8.70801 15.4406V7.1188C9.78107 6.81025 10.5689 5.82037 10.5689 4.65ZM8.0002 5.80312C7.36441 5.80312 6.84863 5.28602 6.84863 4.65156C6.84863 4.01722 7.36585 3.5 8.0002 3.5C8.63454 3.5 9.15176 4.01722 9.15176 4.65156C9.15176 5.28602 8.63599 5.80312 8.0002 5.80312ZM3.49863 8.87964V0.557813C3.49863 0.167157 3.18148 -0.15 2.79082 -0.15C2.40017 -0.15 2.08301 0.167157 2.08301 0.557813V8.87964C1.00995 9.18819 0.22207 10.1781 0.22207 11.3484C0.22207 12.5186 1.00975 13.5101 2.08301 13.8174V15.4406C2.08301 15.8313 2.40017 16.1484 2.79082 16.1484C3.18148 16.1484 3.49863 15.8313 3.49863 15.4406V13.8172C4.57169 13.5087 5.35957 12.5188 5.35957 11.3484C5.35957 10.1781 4.57169 9.18819 3.49863 8.87964ZM2.79082 12.5016C2.15503 12.5016 1.63926 11.9845 1.63926 11.35C1.63926 10.7157 2.15648 10.1984 2.79082 10.1984C3.42517 10.1984 3.94238 10.7157 3.94238 11.35C3.94238 11.9845 3.42661 12.5016 2.79082 12.5016ZM15.7783 11.3484C15.7783 10.1767 14.9906 9.18667 13.9174 8.87948V0.557813C13.9174 0.167158 13.6002 -0.15 13.2096 -0.15C12.8189 -0.15 12.5018 0.167157 12.5018 0.557813V8.87964C11.4287 9.18819 10.6408 10.1781 10.6408 11.3484C10.6408 12.5186 11.4285 13.5101 12.5018 13.8174V15.4406C12.5018 15.8313 12.8189 16.1484 13.2096 16.1484C13.6002 16.1484 13.9174 15.8313 13.9174 15.4406V13.8174C14.9906 13.5102 15.7783 12.5202 15.7783 11.3484ZM13.2096 12.5016C12.5738 12.5016 12.058 11.9845 12.058 11.35C12.058 10.7157 12.5752 10.1984 13.2096 10.1984C13.8439 10.1984 14.3611 10.7157 14.3611 11.35C14.3611 11.9843 13.8439 12.5016 13.2096 12.5016Z" fill="white" />
    </svg>

  );
};

export default Icon;
