import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_135_248)">
        <path d="M10.5687 6.65C10.5687 5.47974 9.78092 4.48978 8.70776 4.18261V2.55781C8.70776 2.16716 8.39061 1.85 7.99995 1.85C7.6093 1.85 7.29214 2.16716 7.29214 2.55781V4.1812C6.21908 4.48975 5.4312 5.47963 5.4312 6.65C5.4312 7.82016 6.21888 8.81165 7.29214 9.11895V17.4406C7.29214 17.8313 7.6093 18.1484 7.99995 18.1484C8.39061 18.1484 8.70776 17.8313 8.70776 17.4406V9.1188C9.78082 8.81025 10.5687 7.82037 10.5687 6.65ZM7.99995 7.80312C7.36416 7.80312 6.84839 7.28602 6.84839 6.65156C6.84839 6.01722 7.36561 5.5 7.99995 5.5C8.6343 5.5 9.15151 6.01722 9.15151 6.65156C9.15151 7.28602 8.63574 7.80312 7.99995 7.80312ZM3.49839 10.8796V2.55781C3.49839 2.16716 3.18123 1.85 2.79058 1.85C2.39992 1.85 2.08276 2.16716 2.08276 2.55781V10.8796C1.0097 11.1882 0.221826 12.1781 0.221826 13.3484C0.221826 14.5186 1.0095 15.5101 2.08276 15.8174V17.4406C2.08276 17.8313 2.39992 18.1484 2.79058 18.1484C3.18123 18.1484 3.49839 17.8313 3.49839 17.4406V15.8172C4.57145 15.5087 5.35933 14.5188 5.35933 13.3484C5.35933 12.1781 4.57145 11.1882 3.49839 10.8796ZM2.79058 14.5016C2.15479 14.5016 1.63901 13.9845 1.63901 13.35C1.63901 12.7157 2.15623 12.1984 2.79058 12.1984C3.42492 12.1984 3.94214 12.7157 3.94214 13.35C3.94214 13.9845 3.42637 14.5016 2.79058 14.5016ZM15.7781 13.3484C15.7781 12.1767 14.9903 11.1867 13.9171 10.8795V2.55781C13.9171 2.16716 13.6 1.85 13.2093 1.85C12.8187 1.85 12.5015 2.16716 12.5015 2.55781V10.8796C11.4285 11.1882 10.6406 12.1781 10.6406 13.3484C10.6406 14.5186 11.4283 15.5101 12.5015 15.8174V17.4406C12.5015 17.8313 12.8187 18.1484 13.2093 18.1484C13.6 18.1484 13.9171 17.8313 13.9171 17.4406V15.8174C14.9903 15.5102 15.7781 14.5202 15.7781 13.3484ZM13.2093 14.5016C12.5735 14.5016 12.0578 13.9845 12.0578 13.35C12.0578 12.7157 12.575 12.1984 13.2093 12.1984C13.8437 12.1984 14.3609 12.7157 14.3609 13.35C14.3609 13.9843 13.8437 14.5016 13.2093 14.5016Z" fill="#253449" stroke="#253449" strokeWidth="0.3" />
      </g>
      <defs>
        <clipPath id="clip0_135_248">
          <rect width="16" height="16" fill="white" transform="translate(0 2)" />
        </clipPath>
      </defs>
    </svg>


  );
};

export default Icon;
