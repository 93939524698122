import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="55" height="68" { ...props } viewBox="0 0 55 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_322_4203)">
        <path
          d="M18.2173 12.0341C17.252 10.5324 16.6844 7.43543 16.644 5.98323C17.8139 5.98323 20.9136 5.77566 22.8159 7.67753C24.8956 9.75671 25.559 13.0829 25.3573 14.9385H24.8732C21.8478 15.0595 19.6455 14.256 18.2173 12.0341Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M40.2038 10.3137C42.5015 7.75008 42.5353 3.23528 42.5967 1C40.8174 1 37.2514 1.75001 34.1255 3.90442C30.7463 6.23333 29.037 11.928 29.3438 14.7842H30.0801C34.6818 14.9705 37.9228 12.8586 40.2038 10.3137Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M29.0275 23.5852C36.8285 19.6248 43.3141 21.935 45.5818 23.5852C43.0421 23.5852 40.8196 24.3818 40.0258 24.7801C37.1345 25.425 29.9118 29.8445 24.1519 42.3632C18.3919 54.8819 10.6401 57.7081 7.48424 57.5564C2.40457 50.819 1.58812 41.5097 2.15517 37.2988C1.98509 29.0479 9.18492 22.4471 13.9471 21.7074C18.7093 20.9677 23.2449 22.2195 25.5693 23.5852C27.4288 24.6778 28.6496 24.0404 29.0275 23.5852Z"
          stroke="white"
          strokeWidth="3"
        />
        <path
          d="M13.0285 63.8659C13.1405 63.8568 13.2548 63.8429 13.3712 63.8245M13.0285 63.8659C9.6923 64.1377 11.8669 63.9515 13.3712 63.8245M13.0285 63.8659C13.4093 64.6963 15.2107 66.21 19.3695 65.6211L23.4254 64.602C24.7013 63.7904 28.3726 62.7335 32.8513 64.9983C37.33 67.2631 41.5344 64.7718 43.0768 63.2431C47.8374 57.43 56.2273 42.8596 51.7029 31.0826C49.4369 30.422 43.8195 30.0747 39.4779 33.9702C37.9926 35.4235 34.2794 39.7682 31.3089 45.5208C28.3383 51.2735 25.1964 55.5049 23.9967 56.9016C21.8068 59.0356 16.7215 63.2955 13.3712 63.8245"
          stroke="url(#paint0_linear_322_4203)"
          strokeWidth="3"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_322_4203"
          x="-39.5"
          y="-40"
          width="134"
          height="147.414"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_322_4203" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_322_4203" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_322_4203"
          x1="50.4995"
          y1="27"
          x2="15.4995"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.354167" stopColor="white" />
          <stop offset="0.791667" stopColor="white" stopOpacity="0.38" />
          <stop offset="0.994792" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
