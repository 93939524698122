export interface ColumnsDefTypes {
  id: number | string
  label: string
  name: string
  translationId: number
  sortable: boolean
}
// export interface TableProps {
//   data?: TableDataTypes[]
//   selectedFilters?: string
//   sortBy?: string
//   sortDir?: string
//   onSort?: (value: string) => void
// }

// export interface ScrollBarProps {
//   ref: string
//   width: number
// }

// export interface TableDataTypes {
//   POOL: string
//   APR: string
//   EARNED: string
//   STAKED: string
//   DETAILS: string
//   LINKS: string
// }

export const MobileColumnSchema: ColumnsDefTypes[] = [
  {
    id: 1,
    name: 'farm',
    translationId: 999,
    sortable: true,
    label: '',
  },
  {
    id: 2,
    name: 'earned',
    translationId: 1072,
    sortable: true,
    label: 'Earned',
  },
  {
    id: 3,
    name: 'apr',
    translationId: 736,
    sortable: true,
    label: 'APR',
  },
  {
    id: 6,
    name: 'details',
    translationId: 999,
    sortable: true,
    label: '',
  },
]

export const DesktopColumnSchema: ColumnsDefTypes[] = [
  {
    id: 1,
    name: 'farm',
    translationId: 999,
    sortable: true,
    label: '',
  },
  {
    id: 2,
    name: 'earned',
    translationId: 1072,
    sortable: true,
    label: 'farms.earned',
  },
  {
    id: 3,
    name: 'apr',
    translationId: 736,
    sortable: true,
    label: 'farms.apr',
  },
  {
    id: 4,
    name: 'liquidity',
    translationId: 999,
    sortable: true,
    label: 'farms.liquidity',
  },
  {
    id: 5,
    name: 'multiplier',
    translationId: 999,
    sortable: true,
    label: 'farms.multiplier',
  },
  {
    id: 6,
    name: 'details',
    translationId: 999,
    sortable: true,
    label: '',
  },
]

export const ViewMode = {
  TABLE: 'TABLE',
  CARD: 'CARD',
}
