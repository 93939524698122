import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="13" height="15" viewBox="0 0 13 15" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
        <path d="M0.195312 8.28125H1.74805C1.85547 8.28125 1.94336 8.21094 1.94336 8.125V3.13867H14.8486V4.55664C14.8486 4.59375 14.8633 4.62891 14.8926 4.6582C14.9634 4.72852 15.0903 4.74219 15.1782 4.68555L18.6768 2.48828C18.7817 2.39062 18.7646 2.28711 18.6768 2.23047L15.1782 0.0351563C15.1416 0.0117188 15.0977 0 15.0513 0C14.939 0 14.8462 0.0742186 14.8462 0.164062V1.58203H1.75049C0.786133 1.58203 0 2.21094 0 2.98437V8.125C0 8.21094 0.0878906 8.28125 0.195312 8.28125ZM18.5547 6.71875H17.002C16.8945 6.71875 16.8066 6.78906 16.8066 6.875V11.8613H3.90137V10.4434C3.90137 10.4062 3.88672 10.3711 3.85742 10.3418C3.78662 10.2715 3.65967 10.2578 3.57178 10.3145L0.0732422 12.5117C-0.0317383 12.6094 -0.0146484 12.7129 0.0732422 12.7695L3.57178 14.9648C3.6084 14.9883 3.65234 15 3.69873 15C3.81104 15 3.90381 14.9258 3.90381 14.8359V13.418H17.0044C17.9688 13.418 18.7549 12.7891 18.7549 12.0156V6.875C18.75 6.78906 18.6621 6.71875 18.5547 6.71875Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
