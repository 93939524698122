import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M10.2976 16.8443C7.59355 16.8443 5.87239 16.2253 5.8008 16.1985C5.5383 16.1015 5.3623 15.8525 5.3623 15.5721V7.88498C5.3623 7.51658 5.6606 7.2168 6.03049 7.2168C6.40037 7.2168 6.69867 7.51509 6.69867 7.88498V15.0754C7.32956 15.2424 8.60478 15.5094 10.2991 15.5094C12.0098 15.5094 13.2985 15.2409 13.9353 15.0739V7.88498C13.9353 7.51658 14.2336 7.2168 14.6035 7.2168C14.9734 7.2168 15.2717 7.51509 15.2717 7.88498V15.5721C15.2717 15.8539 15.0942 16.106 14.8287 16.2C14.7541 16.2268 12.9987 16.8443 10.2976 16.8443Z" />
      <path d="M15.8565 9.63001C15.7193 9.63001 15.5806 9.58675 15.4612 9.49727C15.3151 9.3869 12.0994 6.59336 10.3186 5.04371L5.2476 9.41673C4.96869 9.65685 4.5466 9.62553 4.30647 9.34663C4.06635 9.06772 4.09618 8.64563 4.37657 8.4055L9.88311 3.65664C10.1352 3.44038 10.5065 3.44038 10.7571 3.65813C12.8676 5.49414 16.0772 8.28171 16.2711 8.43682C16.5545 8.66502 16.6082 9.08114 16.3845 9.369C16.2532 9.53903 16.0548 9.63001 15.8565 9.63001Z" />
      <path d="M10.4853 13.2127H10.1483C9.03711 13.2127 8.13477 12.3089 8.13477 11.1992V10.8621C8.13477 9.75098 9.0386 8.84863 10.1483 8.84863H10.4853C11.5965 8.84863 12.4988 9.75247 12.4988 10.8621V11.1992C12.4988 12.3089 11.595 13.2127 10.4853 13.2127ZM10.1468 10.1805C9.77241 10.1805 9.46815 10.4848 9.46815 10.8591V11.1962C9.46815 11.5706 9.77241 11.8748 10.1468 11.8748H10.4838C10.8582 11.8748 11.1625 11.5706 11.1625 11.1962V10.8591C11.1625 10.4848 10.8582 10.1805 10.4838 10.1805H10.1468Z" />
    </Svg>
  )
}

export default Icon
