import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="13" height="15" viewBox="0 0 13 15" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M0.59082 2.20459C0.59082 1.74046 0.782378 1.29534 1.12335 0.967153C1.46433 0.638964 1.92679 0.45459 2.409 0.45459H10.7726C11.2548 0.45459 11.7173 0.638964 12.0583 0.967153C12.3993 1.29534 12.5908 1.74046 12.5908 2.20459V12.1796C12.5908 12.3188 12.5334 12.4524 12.4311 12.5508C12.3288 12.6493 12.19 12.7046 12.0454 12.7046H1.68173C1.68173 12.8902 1.75835 13.0683 1.89474 13.1996C2.03113 13.3308 2.21612 13.4046 2.409 13.4046H12.0454C12.19 13.4046 12.3288 13.4599 12.4311 13.5584C12.5334 13.6568 12.5908 13.7904 12.5908 13.9296C12.5908 14.0688 12.5334 14.2024 12.4311 14.3008C12.3288 14.3993 12.19 14.4546 12.0454 14.4546H2.409C1.92679 14.4546 1.46433 14.2702 1.12335 13.942C0.782378 13.6138 0.59082 13.1687 0.59082 12.7046V2.20459ZM6.59082 4.65459C6.68634 4.6546 6.78092 4.63651 6.86917 4.60134C6.95742 4.56616 7.03761 4.5146 7.10515 4.4496C7.1727 4.3846 7.22628 4.30743 7.26283 4.22249C7.29939 4.13756 7.3182 4.04652 7.3182 3.95459C7.3182 3.86266 7.29939 3.77162 7.26283 3.68669C7.22628 3.60175 7.1727 3.52458 7.10515 3.45958C7.03761 3.39457 6.95742 3.34302 6.86917 3.30784C6.78092 3.27267 6.68634 3.25458 6.59082 3.25459C6.39795 3.25462 6.213 3.32838 6.07663 3.45965C5.94027 3.59093 5.86366 3.76896 5.86366 3.95459C5.86366 4.14022 5.94027 4.31825 6.07663 4.44953C6.213 4.5808 6.39795 4.65456 6.59082 4.65459ZM6.04537 5.87959V9.37959C6.04537 9.51883 6.10283 9.65236 6.20513 9.75082C6.30742 9.84928 6.44616 9.90459 6.59082 9.90459C6.73548 9.90459 6.87422 9.84928 6.97651 9.75082C7.07881 9.65236 7.13627 9.51883 7.13627 9.37959V5.87959C7.13627 5.74035 7.07881 5.60682 6.97651 5.50836C6.87422 5.4099 6.73548 5.35459 6.59082 5.35459C6.44616 5.35459 6.30742 5.4099 6.20513 5.50836C6.10283 5.60682 6.04537 5.74035 6.04537 5.87959Z" />
    </Svg>
  );
};

export default Icon;
