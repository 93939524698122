import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.4652 6.62876C8.4652 4.57876 9.3552 2.75876 10.7652 1.48876C11.1952 1.09876 11.0652 0.388764 10.5052 0.248764C9.1652 -0.0812356 7.7752 -0.0812356 6.4352 0.238764C3.3152 0.978764 0.825202 3.53876 0.185202 6.68876C-0.344798 9.18876 0.275202 11.7988 1.8852 13.7888C3.4952 15.7788 5.9152 16.9388 8.4752 16.9388C10.6452 16.9388 12.7252 16.1088 14.2952 14.6088C14.7052 14.2188 14.4752 13.5288 13.9252 13.4088C10.7352 12.6988 8.4752 9.87877 8.4652 6.62876Z" fill="white"/>
</svg>



  );
};

export default Icon;
