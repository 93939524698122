import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_97_2)">
        <path d="M16 0C7.2 0 0 7.2 0 16C0 24.8 7.2 32 16 32C24.8 32 32 24.8 32 16C32 7.2 24.8 0 16 0Z" fill="#2980FE" />
        <path d="M13.5001 8.7002H13.3001H5.3001C4.9001 8.7002 4.6001 9.0002 4.6001 9.4002V12.2002C4.6001 12.6002 4.9001 12.9002 5.3001 12.9002H7.2001H8.0001V13.7002V22.6002C8.0001 23.0002 8.3001 23.3002 8.7001 23.3002H11.7001C12.1001 23.3002 12.4001 23.0002 12.4001 22.6002V13.6002V13.5002V12.7002H13.2001H13.5001H13.7001C14.9001 12.7002 15.8001 11.8002 15.8001 10.6002C15.6001 9.6002 14.7001 8.7002 13.5001 8.7002Z" fill="white" />
        <path d="M27.4003 14.5002C27.4003 17.6002 25.0003 20.2002 21.9003 20.3002H21.6003V15.8002C21.7003 15.8002 21.8003 15.8002 21.9003 15.8002C22.5003 15.7002 22.9003 15.1002 22.9003 14.5002C22.9003 13.8002 22.3003 13.2002 21.6003 13.2002C20.9003 13.2002 20.3003 13.8002 20.3003 14.5002V22.6002C20.3003 23.0002 20.0003 23.3002 19.6003 23.3002H16.5003C16.1003 23.3002 15.8003 23.0002 15.8003 22.6002V14.5002C15.8003 11.3002 18.4003 8.7002 21.6003 8.7002C24.8003 8.7002 27.4003 11.3002 27.4003 14.5002Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_97_2">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default Icon;
