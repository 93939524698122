import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.11851 17.6613L1.4928 14.3574L8.13589 11.116C8.75858 10.8122 9.48653 10.8122 10.1092 11.116L16.7523 14.3574L10.1266 17.6613C9.49436 17.9765 8.75076 17.9765 8.11851 17.6613Z" fill="#2D2F60" stroke="white" />
<path d="M7.86978 6.76566C8.49247 6.46182 9.22042 6.46182 9.84311 6.76565L16.4862 10.0071L9.86049 13.3109C9.22825 13.6262 8.48464 13.6262 7.8524 13.3109L1.22668 10.0071L7.86978 6.76566Z" fill="#2D2F60" stroke="white" />
<path d="M8.15689 2.31595C8.77958 2.01211 9.50753 2.01211 10.1302 2.31595L16.7733 5.55735L10.1476 8.86123C9.51536 9.1765 8.77175 9.1765 8.13951 8.86123L1.51379 5.55735L8.15689 2.31595Z" fill="#2D2F60" stroke="white" />
</svg>


  );
};

export default Icon;
