import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="14" { ...props } viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1685 0.562001C10.7783 0.562001 6.38817 0.561921 1.99801 0.562081C1.36201 0.562081 0.868966 0.981921 0.771686 1.6044C0.769366 1.61928 0.763766 1.63368 0.759766 1.6484V12.3511C0.772726 12.4118 0.783446 12.4728 0.799046 12.5328C0.936886 13.0638 1.40953 13.4334 1.95777 13.437C2.31097 13.4391 2.66409 13.4374 3.01729 13.4374H15.2398V0.562001H15.1685ZM14.4367 12.6327H14.3618C10.5068 12.6327 6.65177 12.6328 2.79665 12.6326C2.26825 12.6326 1.82265 12.33 1.64217 11.8502C1.63457 11.83 1.62857 11.8091 1.62121 11.7866C2.03553 11.8638 2.44929 11.8258 2.86089 11.8261C6.68689 11.8293 10.5129 11.8279 14.339 11.8279H14.4367V12.6327ZM14.4374 11.0233H14.3642C10.5086 11.0233 6.65313 11.0234 2.79761 11.0231C2.18809 11.0231 1.70185 10.6275 1.58601 10.0353C1.57025 9.9548 1.56537 9.87104 1.56529 9.78888C1.56425 7.39288 1.56441 4.99688 1.56449 2.60088C1.56449 1.89624 2.09281 1.36688 2.79777 1.3668C5.98721 1.3664 9.17665 1.36664 12.3661 1.36664H14.4353V4.98792H10.8634C10.5781 4.98792 10.4164 5.1504 10.4164 5.43744V8.5688C10.4164 8.8464 10.5805 9.01144 10.8567 9.01144L14.3502 9.01152H14.4374V11.0233ZM14.4367 5.79264V8.2068H11.657C11.3884 8.20672 11.2212 8.04032 11.2212 7.77296C11.221 7.25664 11.221 6.74032 11.2212 6.22408C11.2213 5.96104 11.3895 5.79272 11.6529 5.79272C12.558 5.79256 13.4634 5.79272 14.3685 5.79272L14.4367 5.79264Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
