import { ChainId, JSBI, Percent, Token, WETH } from 'openfi-new-sdk'

// export const ROUTER_ADDRESS = '0x10ED43C718714eb63d5aA57B78B54704E256024E'
// export const ROUTER_ADDRESS = '0xb2EA22FEcdF6F28Fe98B338a113B8A5f743c15Bf'
export const ROUTER_ADDRESS = '0xCB1dd18E2666b7e0da6F8cB2f8d088e3aBAAAC83'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const WBNB = new Token(ChainId.MAINNET, WETH[ChainId.MAINNET].address, 18, 'WBNB', 'PancakeSwap Token')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'Binance USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const USDT = new Token(ChainId.MAINNET, '0x1c63D906134E1c7B2d545591216C83a9612b59b3', 18, 'USDT', 'Tether USD')
export const CAKE = new Token(ChainId.MAINNET, '0xDa42c2A8cBb48da4f8710f642F035a5FeF02185A', 18, 'USDT', 'Tether USD')

export const WFIL = new Token(ChainId.MAINNET, '0xcE5805CF6C84F71D2897f632E0Aa60d2430cCd2A', 18, 'WFIL', 'WFIL')
export const OPEN = new Token(ChainId.MAINNET, '0x02128A6185083772A3Ea8a0320d6349806321f6d', 9, 'OPEN', 'OPEN')
export const NEC = new Token(ChainId.MAINNET, '0x344ecE9B65119E7De0A37D46F61e789DA052931E', 18, 'NEC', 'NEC')
// export const SC = new Token(ChainId.MAINNET, '0xFF25faB7a4940eC32CB2ca0E9566694ED9BB28A1', 18, 'SC', 'SC')
// export const THS = new Token(ChainId.MAINNET, '0x005E02A4A934142d8Dd476F192d0dD9c381b16b4', 9, 'THS', 'THS')

// export const UST = new Token(
//   ChainId.MAINNET,
//   '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
//   18,
//   'UST',
//   'Wrapped UST Token'
// )
export const ETH = new Token(
  ChainId.MAINNET, 
  '0xee9b5b3285998C84D52D0369580Adc19E935b0cE',
  18,
  'ETH',
  'Binance-Peg Ethereum Token'
)

const WETH_ONLY: (ChainTokenList) = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]]
}
console.log("WETH===>", WETH)
console.log("WETH_ONLY===>", WETH_ONLY)
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, BTCB, USDT, ETH],
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, BTCB, USDT, ETH, WETH, OPEN, NEC],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], OPEN, NEC],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, BTCB, USDT],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    // [CAKE, WOKT],
    [BUSD, USDT],
    [DAI, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
