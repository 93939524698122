import { MenuEntry } from 'uikit'

// export const HOST = 'https://tsl-stage.netlify.app/'
export const HOST = 'https://dapp.openfi.pro'
// export const HOST = 'http://192.168.0.100:3000'


const config: MenuEntry[] = [
  // {
  //   label: 'Home',
  //   icon: 'HomeIcon',
  //   href: '/home',
  // },
  // {
  //   label: 'Pro IDO',
  //   icond: 'Idocon',
  //   iconL: 'IdoLcon',
  //   // href: '/pools',
  //   href: `${HOST}/ido`,
  //   comming: false
  // },
  {
    label: 'Dashboard',
    icond: 'DashBoardIcon',
    iconL: 'DashBoardLIcon',
    // href: '/pools',
    href: `${HOST}/dashboard`,
    comming: false
  },
  {
    label: 'Swap',
    icond: 'SwapIcon',
    iconL: 'SwapLIcon',
    initialOpenState: true,
    // status: {
    //   text: 'MIGRATE',
    //   color: 'warning',
    // },
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  // {
  //   label: 'Bridge',
  //   icon: 'BridgeIcon',
  //   // href: '/farms',
  //   href: '#',
  //   comming: false
  // },
  
  {
    label: 'Stake',
    icond: 'StakeIcon',
    iconL: 'StakeLIcon',
    // href: '/pools',
    href: `${HOST}/stake`,
    comming: false
  },
  {
    label: 'Bond',
    icond: 'BondIcon',
    iconL: 'BondLIcon',
    // href: '/pools',
    href: `${HOST}/bonds`,
    comming: false
  },
  {
    label: 'Claim Profit',
    icond: 'ClaimPIcon',
    iconL: 'ClaimPLIcon',
    // href: '/pools',
    href: `${HOST}/claim`,
    comming: false
  },
  {
    label: 'SC Mining',
    icond: 'ScIcon',
    iconL: 'SCLIcon',
    // href: '/pools',
    href: `${HOST}/sc`,
    comming: false
  },
  {
    label: 'Faucet',
    icond: 'FaucetIcon',
    iconL: 'FaucetLIcon',
    // href: '/pools',
    href: `${HOST}/faucet`,
    comming: false
  },
  {
    label: 'Perpetual Trade',
    icond: 'PerpeIcon',
    iconL: 'PerpeLIcon',
    // href: '/pools',
    href: `${HOST}/trade`,
    comming: false
  },
  
  // {
  //   label: 'Convert',
  //   icon: 'ConvertIcon',
  //   // href: '/pools',
  //   href: `${HOST}/convert`,
  //   comming: false
  // },
  // {
  //   label: 'IDO Release',
  //   icon: 'IdoRcon',
  //   // href: '/pools',
  //   href: `${HOST}/IDORelease`,
  //   comming: false
  // },
  // {
  //   label: 'Claim',
  //   icon: 'ClaimIcon',
  //   // href: '/pools',
  //   href: `${HOST}#/claim_profit`,
  //   comming: false
  // },
  // {
  //   label: 'DAORewards',
  //   icon: 'DAORewards',
  //   // href: '/pools',
  //   href: `${HOST}#/dao_rewards`,
  //   comming: false
  // },
  // {
  //   label: 'tokenList',
  //   icon: 'TokenListIcon',
  //   // href: '/pools',
  //   href: `${HOST}#/token_list`,
  //   comming: false
  // },

  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   comming: true,
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: '#',
  //       // href: 'https://info.mangoswap.exchange',
  //       // target: '_blank'
  //     },
  //     {
  //       label: 'Tokens',
  //       href: '#',
  //       // href: 'https://info.mangoswap.exchange/tokens',
  //       // target: '_blank'
  //     },
  //     {
  //       label: 'Pairs',
  //       href: '#',
  //       // href: 'https://info.mangoswap.exchange/pairs',
  //       // target: '_blank'
  //     },
  //     {
  //       label: 'Accounts',
  //       href: '#',
  //       // href: 'https://info.mangoswap.exchange/accounts',
  //       // target: '_blank'
  //     },
  //   ],
  // },
  // // {
  // //   label: 'IFO',
  // //   icon: 'IfoIcon',
  // //   href: '/ifo',
  // // } ,
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items:[
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/MangoswapDefi/mangoswap', 
  //       target: '_blank'
  //     },
  //     {
  //       label: 'Twitter',
  //       href: 'https://twitter.com/MangoSwap_1',
  //       target: '_blank'
  //     },
  //     {
  //       label: 'Telegram',
  //       href: 'https://t.me/MangoSwapOfficial',
  //       target: '_blank'
  //     },
  //     {
  //       label: 'Medium',
  //       href: 'https://medium.com/@MangoSwap_1',
  //       target: '_blank'
  //     },
  //     // {
  //     //   label: 'Docs',
  //     //   href: 'https://github.com/mangoswap/swap/blob/dev/docs/KeyneSwepDocs.pdf',
  //     //   target: '_blank'
  //     // },
  //     // {
  //     //   label: 'Blog',
  //     //   href: 'https://github.com/mangoswap/swap',
  //     //   target: '_blank'
  //     // }
  //   ]
  // } 
]

export default config
