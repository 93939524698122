import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" { ...props } xmlns="http://www.w3.org/2000/svg">
        <path d="M2.8467 10.0011V10.0011C2.8467 13.9464 6.05472 17.1556 10 17.1556C13.9453 17.1556 17.1522 13.9475 17.1522 10.0011V10.0011C17.1507 8.10459 16.3967 6.28617 15.0558 4.94504C13.7148 3.6039 11.8966 2.84964 10 2.84783L9.99995 2.84783C8.10335 2.84964 6.28494 3.60386 4.94383 4.94496C3.60273 6.28607 2.84851 8.10448 2.8467 10.0011ZM10 18.95C5.06502 18.95 1.05 14.935 1.05 10C1.05 5.06502 5.06502 1.05 10 1.05C14.935 1.05 18.95 5.06502 18.95 10C18.95 14.935 14.9339 18.95 10 18.95Z" fill="white" stroke="#141722" strokeWidth="0.1"/>
        <path d="M9.94463 15.6443L4.51367 10.0823L10.0781 4.65137L15.5078 10.2158L9.94463 15.6443ZM6.7352 10.1098L9.97082 13.4252L13.2838 10.1896L10.0519 6.8729L6.7352 10.1098Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
