import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.69 15.3902C12.48 15.3902 12.27 15.3202 12.1 15.1802C11.91 15.0202 11.78 14.8002 11.76 14.5502C11.73 14.3002 11.81 14.0602 11.96 13.8602L12.89 12.7102H5.20001C4.68001 12.7102 4.26001 12.2902 4.26001 11.7702C4.26001 11.2502 4.68001 10.8302 5.20001 10.8302H14.85C15.21 10.8302 15.54 11.0402 15.7 11.3602C15.86 11.6802 15.81 12.0802 15.58 12.3602L13.42 15.0402C13.24 15.2602 12.98 15.3902 12.69 15.3902ZM5.20001 8.51023C4.84001 8.51023 4.51001 8.30023 4.35001 7.97023C4.20001 7.64023 4.24001 7.25023 4.47001 6.97023L6.63001 4.33023C6.81001 4.11023 7.07001 3.99023 7.36001 3.99023C7.58001 3.99023 7.79001 4.07023 7.95001 4.20023C8.35001 4.53023 8.41001 5.12023 8.08001 5.52023L7.18001 6.62023H14.85C15.37 6.62023 15.79 7.04023 15.79 7.56023C15.79 8.08023 15.37 8.50023 14.85 8.50023H5.20001V8.51023Z" fill="#202251" />
      <path d="M10 20C4.49 20 0 15.51 0 10C0 4.49 4.49 0 10 0C15.51 0 20 4.49 20 10C20 15.51 15.51 20 10 20ZM10 1.88C5.52 1.88 1.88 5.52 1.88 10C1.88 14.48 5.52 18.12 10 18.12C14.48 18.12 18.12 14.48 18.12 10C18.12 5.52 14.48 1.88 10 1.88Z" fill="#202251" />
    </svg>




  );
};

export default Icon;
