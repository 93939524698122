import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="16" viewBox="0 0 20 16" { ...props } fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.57085 1.78138C4.37328 1.78138 1.78138 4.37328 1.78138 7.57085C1.78138 10.7684 4.37328 13.3603 7.57085 13.3603C10.7684 13.3603 13.3603 10.7684 13.3603 7.57085C13.3603 4.37328 10.7684 1.78138 7.57085 1.78138ZM0 7.57085C0 3.38951 3.38951 -2.08755e-08 7.57085 0C11.7522 2.08755e-08 15.1417 3.38951 15.1417 7.57085C15.1417 11.7522 11.7522 15.1417 7.57085 15.1417C3.38951 15.1417 0 11.7522 0 7.57085Z" fill="white"/>
      <path d="M12.4283 1.78138C9.2307 1.78138 6.6388 4.37328 6.6388 7.57085C6.6388 10.7684 9.2307 13.3603 12.4283 13.3603C15.6258 13.3603 18.2177 10.7684 18.2177 7.57085C18.2177 4.37328 15.6258 1.78138 12.4283 1.78138ZM4.85742 7.57085C4.85742 3.38951 8.24694 -2.08755e-08 12.4283 0C16.6096 2.08755e-08 19.9991 3.38951 19.9991 7.57085C19.9991 11.7522 16.6096 15.1417 12.4283 15.1417C8.24694 15.1417 4.85742 11.7522 4.85742 7.57085Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
