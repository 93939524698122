import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.8106 6.03945L16.8108 6.0397C16.9202 6.14832 17.1072 6.16725 17.2395 6.08232C17.2397 6.08221 17.2399 6.08209 17.2401 6.08197L20.9699 3.73884L20.9779 3.73382L20.9848 3.72738C21.055 3.66211 21.0978 3.58186 21.0937 3.49554C21.0896 3.40826 21.0386 3.33877 20.9709 3.29511L20.9698 3.29447L17.2398 0.95328C17.2397 0.953198 17.2395 0.953115 17.2394 0.953033C17.1824 0.916668 17.1165 0.9 17.0505 0.9C16.8975 0.9 16.7318 1.00429 16.7318 1.175V2.5875H2.8667C1.80495 2.5875 0.9 3.28353 0.9 4.18333V9.66667C0.9 9.83321 1.06045 9.93333 1.20828 9.93333H2.8641C3.01193 9.93333 3.17238 9.83321 3.17238 9.66667V4.44792H16.7344V5.86042C16.7344 5.92825 16.7618 5.99071 16.8106 6.03945ZM16.8813 5.96875C16.9568 6.04375 17.0922 6.05833 17.1859 5.99792L16.8344 4.44792V5.86042C16.8344 5.9 16.85 5.9375 16.8813 5.96875ZM5.26298 15.4125H19.1333C20.1951 15.4125 21.1 14.7165 21.1 13.8167V8.33333H21.1002L21.0998 8.32766C21.0907 8.1673 20.9367 8.06667 20.7865 8.06667H19.1307C18.9829 8.06667 18.8224 8.16679 18.8224 8.33333V13.5521H5.26037V12.1396C5.26037 12.0718 5.23296 12.0093 5.18423 11.9605L5.18399 11.9603C5.07462 11.8517 4.88758 11.8327 4.75522 11.9177C4.75505 11.9178 4.75489 11.9179 4.75472 11.918L1.02491 14.2612L1.0169 14.2662L1.00998 14.2726C0.939841 14.3379 0.897006 14.4181 0.901088 14.5045C0.905215 14.5917 0.956208 14.6612 1.02392 14.7049L1.02494 14.7055L4.75499 17.0467C4.75514 17.0468 4.7553 17.0469 4.75545 17.047C4.81242 17.0834 4.87831 17.1 4.94429 17.1C5.09732 17.1 5.26298 16.9957 5.26298 16.825V15.4125ZM5.11351 12.0312C5.03801 11.9563 4.90263 11.9417 4.8089 12.0021L5.16037 13.5521V12.1396C5.16037 12.1 5.14475 12.0625 5.11351 12.0312ZM18.8224 13.6521H5.26037H18.8224Z" fill="white"/>
</svg>


  );
};

export default Icon;
