import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00034 9.28198C6.8095 9.28198 4.54611 8.92269 2.82663 8.22621C1.11599 7.53331 -0.099329 6.48685 -0.099329 5.09099C-0.099329 3.69513 1.11599 2.64868 2.82663 1.95578C4.54611 1.25929 6.8095 0.9 9.00034 0.9C11.1912 0.9 13.4546 1.25929 15.174 1.95578C16.8847 2.64868 18.1 3.69513 18.1 5.09099C18.1 6.48685 16.8847 7.53331 15.174 8.22621C13.4546 8.92269 11.1912 9.28198 9.00034 9.28198ZM9.00034 2.73537C6.763 2.73537 4.93433 3.09705 3.66836 3.58054C3.03491 3.82246 2.54623 4.09339 2.21792 4.36167C1.88527 4.63349 1.73604 4.88665 1.73604 5.09099C1.73604 5.29531 1.88525 5.5483 2.21789 5.81988C2.54619 6.08792 3.03487 6.35854 3.66831 6.60014C4.93429 7.08299 6.76298 7.44404 9.00034 7.44404C11.2377 7.44404 13.0663 7.08236 14.3323 6.59918C14.9658 6.35742 15.4545 6.08672 15.7828 5.81878C16.1155 5.54727 16.2646 5.29461 16.2646 5.09099C16.2646 4.88735 16.1155 4.63453 15.7828 4.36277C15.4544 4.09459 14.9657 3.82357 14.3323 3.5815C13.0663 3.09769 11.2376 2.73537 9.00034 2.73537Z" fill="#253449" stroke="#253449" strokeWidth="0.2"/>
<path d="M8.92637 14.1908L8.92814 14.1909C8.99054 14.1908 9.05303 14.1915 9.11578 14.1921L9.11684 14.1921C9.17907 14.1928 9.24159 14.1934 9.30355 14.1934C11.4593 14.1934 13.4956 13.8734 15.0706 13.274C16.9686 12.5526 18.0755 11.4567 18.0972 10.1611C18.0972 10.1611 18.0972 10.161 18.0972 10.1609L17.9972 10.1593L8.92637 14.1908ZM8.92637 14.1908C6.73553 14.1521 4.47861 13.7527 2.77147 13.0264C1.07319 12.3039 -0.123787 11.2372 -0.0995159 9.84388M8.92637 14.1908L14.4159 11.5571M14.4159 11.5571L14.4158 11.5572C12.9708 12.1067 11.043 12.3811 8.95798 12.3555C6.72074 12.3145 4.89853 11.9203 3.64117 11.4148C3.01201 11.1619 2.52812 10.8827 2.20459 10.6091C1.87691 10.332 1.73231 10.0769 1.73581 9.87341C1.73795 9.79088 1.76236 9.70708 1.8198 9.60819C2.07918 9.16969 1.932 8.60726 1.49397 8.35045C1.06305 8.09327 0.495047 8.23796 0.238243 8.67637C0.0192528 9.04763 -0.0940647 9.43981 -0.0995159 9.84388M14.4159 11.5571C14.9854 11.341 15.4505 11.0871 15.7721 10.8306C16.0992 10.5697 16.2562 10.3238 16.2593 10.1268L16.2593 10.1266M14.4159 11.5571L16.2593 10.1266M-0.0995159 9.84388C-0.0995159 9.844 -0.0995216 9.84412 -0.0995216 9.84424L0.000469208 9.84557L-0.0995159 9.84383C-0.0995159 9.84384 -0.0995159 9.84386 -0.0995159 9.84388ZM16.2593 10.1266C16.2613 10.0171 16.2144 9.88378 16.1057 9.73157M16.2593 10.1266L16.1057 9.73157M16.1057 9.73157C15.8093 9.31978 15.898 8.74569 16.3083 8.4481L16.367 8.52905L16.1057 9.73157Z" fill="#253449" stroke="#253449" strokeWidth="0.2"/>
<path d="M1.61463 13.2902L1.61489 13.2904C2.0329 13.5729 2.14882 14.1441 1.8628 14.5659L1.86278 14.566C1.77237 14.6992 1.73604 14.811 1.73604 14.9084C1.73604 15.112 1.88519 15.3648 2.2179 15.6366C2.54623 15.9048 3.03493 16.1758 3.66838 16.4179C4.93436 16.9017 6.76303 17.264 9.00034 17.264C11.2376 17.264 13.0663 16.9017 14.3323 16.4179C14.9657 16.1758 15.4544 15.9048 15.7828 15.6366C16.1155 15.3648 16.2646 15.112 16.2646 14.9084C16.2646 14.8125 16.2301 14.7053 16.1498 14.5825L16.1498 14.5825C15.873 14.1587 15.9882 13.5903 16.4124 13.3104L16.4124 13.3104L16.4128 13.3101C16.8369 13.0331 17.405 13.1515 17.6847 13.5725L17.6847 13.5725L17.6851 13.573C17.9597 13.9916 18.1 14.4398 18.1 14.9058C18.1 16.3029 16.8847 17.35 15.1741 18.0432C13.4546 18.7401 11.1912 19.0994 9.00034 19.0994C6.8095 19.0994 4.54611 18.7401 2.82663 18.0436C1.11599 17.3507 -0.099329 16.3042 -0.099329 14.9084C-0.099329 14.4314 0.0491524 13.97 0.339403 13.5386C0.622496 13.1169 1.19384 13.0078 1.61463 13.2902Z" fill="#253449" stroke="#253449" strokeWidth="0.2"/>
</svg>



  );
};

export default Icon;
