import global from './zhCN/global'
import { Language } from "./type"

const locale = {
  ...global,

  'preMiner.days': '天',
  'preMiner.hours': '时',
  'preMiner.mins': '分',
  'preMiner.left': '剩余',
  'preMiner.unstake': '赎回 {n}',
  'preMiner.stake': '质押 {n}',
  'preMiner.volumeMined': '挖矿进度',
  'preMiner.youStake': '您的质押',
  'preMiner.ofTotal': '总进度',
  'preMiner.yourCoinEarn': '您{coinName}的收益',


  'menu.Home': 'Home',
  'menu.Trade': 'Trade',
  'menu.Farms': 'Farms',
  'menu.Pools': 'Pools',
  'menu.Info': 'Info',
  'menu.IFO': 'IFO',
  'menu.More': 'More',
  'menu.Exchange': 'Exchange',
  'menu.Liquidity': 'Liquidity',
  'menu.Overview': 'Overview',
  'menu.Tokens': 'Tokens',
  'menu.Pairs': 'Pairs',
  'menu.Accounts': 'Accounts',
  'menu.Contect': 'Contect',
  'menu.Github': 'Github',
  'menu.Docs': 'Docs',
  'menu.Blog': 'Blog',
  'menu.connect': '連接錢包',
  'menu.Twitter': 'Twitter',
  'menu.Facebook': 'Facebook',
  'menu.Telegram': 'Telegram',
  'menu.Medium': 'Medium',
  'menu.DEX': 'Dex',
  'menu.Bridge': 'Bridge',
  'menu.Invest': 'Invest',
  'menu.Claim': 'Claim Profit',
  'menu.DAORewards': 'DAO Rewards',
  'menu.tokenList': 'Token List', 
  'menu.Dashboard': '儀表板',  
  'menu.Pro IDO': 'Pro IDO',  
  'menu.Swap': 'Swap',  
  'menu.Convert': '轉換',  
  'menu.Stake': '質押',  
  'menu.Bond': '債券',  
  'menu.Claim Profit': '領取利潤',  
  'menu.SC': 'SC',  
  'menu.IDO Release': 'IDO 釋放獎勵',
  'menu.SC Mining': 'NEC Mining',  

  'swap.swap': 'Swap',
  'swap.liquidity': '流動性',
  'swap.exchange': '交換',
  'swap.exchangeInfo': 'Trade tokens in an instant',
  'swap.from': 'From',
  'swap.FromEstimated': 'From (estimated)',
  'swap.to': 'To',
  'swap.toEstimated': 'To (estimated)',
  'swap.addSend': 'Add a send (optional)',
  'swap.removeSend': 'Remove send',
  'swap.insufficientLiquidity': 'Insufficient liquidity for this trade.',
  'swap.approving': 'Approving',
  'swap.approved': 'Approved',
  'swap.approve': 'Approve',
  'swap.priceImpactHigh': 'Price Impact High',
  'swap.balance': 'Balance',
  'swap.max': 'MAX',
  'swap.selectToken': '選擇代幣',
  'swap.selectTokenHint': '通過代幣名字或者符號，或者將代幣合約地址復製過來，找到代幣.',
  'swap.selectTokenPlaceholder': 'token search placeholder',
  'swap.selectTokenInput': 'Search name or paste address',
  'swap.selectCurrency': '選擇代幣',
  'swap.enterAmount': 'Enter an amount',
  'swap.tokenName': 'Token name',
  'swap.settings': '設置',
  'swap.slippageTolerance': 'Slippage tolerance',
  'swap.slippageHint': '如果價格變動超過該百分比，你的交易會被回退.',
  'swap.slippageToleranceZero1': 'Your transaction may fail',
  'swap.slippageToleranceNotInput': 'Enter a valid slippage percentage',
  'swap.slippageToleranceError': 'Your transaction may be frontrun',
  'swap.transactionDeadline': 'Transaction deadline',
  'swap.transactionDeadlineNotInput': 'Enter a valid deadline',
  'swap.minutes': 'Minutes',
  'swap.transactionDeadlineHint': '如果交易時間過長，你的交易會被回退.',
  'swap.recentTransactions': '最近交易',
  'swap.approveUSDT': 'Approve USDT',
  'swap.price': '價格',
  'swap.minimumReceived': 'Minimum received',
  'swap.maximumSold': 'Maximum sold',
  'swap.minimumReceivedHint': '如果在確認前價格變動過大，你的交易會被回退.',
  'swap.priceImpact': 'Price Impact',
  'swap.priceImpactHint': 'The difference between the market price and estimated price due to trade size.',
  'swap.priceImpactHintSec': '如果在確認前價格變動過大，你的交易會被回退.',
  'swap.liquidityProviderFee': 'Liquidity Provider Fee',
  'swap.liquidityProviderFeeHint': 'For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the MangoSwap treasury.',
  'swap.swapButtonError': '價格影響太高',
  'swap.swapButtonInputError': 'Insufficient BNB balance',
  'swap.unlockWallet': 'Unlock Wallet',
  'swap.connectWalletInfo': '請鏈接錢包查看最近的交易',
  'swap.connectWallet': 'Connect to a wallet',
  'swap.learnConnectWallet': 'Learn how to connect',
  'swap.change': 'Change',
  'swap.selectList': 'Select a list',
  'swap.connectWallet2': '连接钱包',
  'swap.invalidPair': 'Invalid pair',
  'swap.enterRecipient': 'Enter a recipient',
  'swap.invalidRecipient': 'Invalid recipient',
  'swap.swapAnyway': 'Swap Anyway',
  'swap.route': 'Route',
  'swap.routeTokens': 'Routing through these tokens resulted in the best price for your trade.',
  'swap.balanceInsufficient': 'Insufficient {symbol} balance',
  'swap.tokenStakePool': 'You’ll need {tokenSymbol} to stake in this pool!',
  'swap.buyTokenHint': 'Buy some {tokenSymbol}, or make sure your {tokenSymbol} isn’t in another pool or LP.',
  'swap.buyToken': 'Buy {tokenSymbol}',
  'swap.tokenRequired': '{tokenSymbol} required',
  'swap.locateAssets': 'Locate Assets',
  'swap.closeWindow': 'Close window',

  'pool.liquidity': 'Liquidity',
  'pool.addLiquidity': 'Add Liquidity',
  'pool.addLiquidityHint': 'Add liquidity to receive LP tokens',
  'pool.yourLiquidity': 'Your Liquidity',
  'pool.yourLiquidityHint': 'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.',
  'pool.connectWalletLiquidity': 'Connect to a wallet to view your liquidity.',
  'pool.noLiquidity': 'No liquidity found.',
  'pool.loading': 'Loading',
  'pool.notSeePool': "Don't see a pool you joined?",
  'pool.importIt': "Import it.",
  'pool.pooled': "Pooled {symbolName}",
  'pool.yourPoolTokens': "Your pool tokens:",
  'pool.yourPoolShare': "Your pool share:",
  'pool.remove': "Remove",
  'pool.noTransactions': "No recent transactions",
  'pool.unstakeTokensHint': "Or, if you staked your LP tokens in a farm, unstake them to see them here.",

  'find.connectWalletPools': "鏈接錢包查看池子",
  'find.add': "增加",
  'find.remove': "去除",
  'find.importPool': "Import Pool",
  'find.importPoolHint': "Use this tool to find pairs that do not automatically appear in the interface.",
  'find.selectToken': "選擇代幣",
  'find.selectTokenInfo': "Select a token to find your liquidity.",
  'find.yourLiquidityHint': 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.',
  'find.removeTokensHint': 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.',
  'find.poolFound': 'Pool found!',
  'find.notLiquidityPool': '該流動池中，你沒有擁有份額.',
  'find.noPoolFound': "沒有找到池子.",
  'find.createPool': "Create pool.",
  'find.invalidPair': "Invalid pair.",

  'add.creatingPool': '你正在建立池子',
  'add.youWillReceive': '你會收到',
  'add.firstProviderHint': '你是第一個流動池提供者.',
  'add.pricePoolHint': '代幣的比例決定了池子裏面代幣的價格.',
  'add.clickSupplyHint': 'Once you are happy with the rate click supply to review.',
  'add.initialPriceAndPool': 'INITIAL PRICES AND POOL SHARE',
  'add.priceAndPool': 'PRICES AND POOL SHARE',
  'add.supply': 'Supply',
  'add.input': 'Input',
  'add.lpTokens': 'LP TOKENS IN YOUR WALLET',
  'add.shareOfPool': 'Share of Pool',
  'add.symbolDeposited': '{symbol} Deposited',
  'add.rates': 'Rates',
  'add.createPoolSupply': 'Create Pool & Supply',
  'add.confirmSupply': 'Confirm Supply',
  

  'fetch.transactionTokensIssue': '交易失敗原因: {reason}. 可能因為你交易的一個代幣.',
  'fetch.transactionTransfer': '該交易因為價格變動或GAS失敗。請提高滑點值',
  'fetch.gasUnexpected': '估算GAS發生意外錯誤，請重新發起交易.',
  'fetch.missDependencies': 'Missing dependencies',
  'fetch.contactSupportError': 'Unexpected error. Please contact support: none of the calls threw an error',
  'fetch.transactionRejected': '交易被拒絕.',
  'fetch.swapFailed': '交易錯誤: {message}',


  // 'home.statistics': '数据统计',
  // 'home.totalLiquidity': '总流动资金',
  // 'home.volume': '交易量',
  // 'home.priceMGS': 'MGS价格',
  // 'home.pools': '资金池',
  // 'home.tokens': '代币',
  // 'home.name': '名称',
  // 'home.liquidity': '流动性',
  // 'home.symbol': '代币符号',
  // 'home.Volume24': '交易量(24时)',
  // 'home.Volume7d': '交易量 (7天)',
  // 'home.fees24h': '手续费(24时)',
  // 'home.fees1y': '年手续费/流动性',
  // 'home.Action': '操作',
  // 'home.price': '价格',
  // 'home.priceChange24h': '价格变化(24时)',
  // 'home.Search': '搜索',
  // 'home.SearchMangoSwap': '搜索MangoSwap',
  // 'home.SearchPairsAndTokens': '正在搜索代币对和代币...',
  // 'home.SearchMangoSwapPairsAndTokens': '搜索MangoSwap代币对和代币...',
  // 'home.volumeAnnualized': '基于24小时年化交易量',
  // 'home.pairs': '代币对',
  // 'home.noResults': '没有找到相关结果',
  // 'home.seeMore': '查看更多',
  // 'home.topText': 'OkexChain上的第一个AMM交易平台和收益农场',


  // 'farms.farms': '农场',
  // 'farms.stakeLiquidityPool': '投入流动性池（LP）代币来赚取。',
  // 'farms.stakedOnly': '只显示我质押的',
  // 'farms.sortBy': '排序',
  // 'farms.search': '搜索',
  // 'farms.hot': '热门',
  // 'farms.apr': '年化利率',
  // 'farms.multiplier': '倍数',
  // 'farms.earned': '已赚取',
  // 'farms.liquidity': '流行性',
  // 'farms.details': '详细信息',
  // 'farms.searchFarms': '搜索...',
  // 'farms.toTop': '回到顶部',
  // 'farms.rowTableLabel': '{label2}',
  // 'farms.totalFunds': '该农场流动性池中资金的总价值',
  // 'farms.multiplierRepresents': '倍数代表每个农场得到的KNS奖励的数量。',
  // 'farms.multiplierNumberRatio': '例如，如果一个1x农场每一块得到一个KNS奖励，{number}x农场每一块得到{number2}个KNS。',
  // 'farms.totalLiquidity': '流动性总量',
  // 'farms.viewContract': '查看合约',
  // 'farms.seePairInfo': '查看代币对信息',
  // 'farms.startFarming': '开始耕种',
  // 'farms.staked': '已质押',
  // 'farms.stake': '质押',
  // 'farms.stakeLP': '质押LP代币',
  // 'farms.enableFarm': '启用农场',
  // 'farms.enable': '启用',

  'farms.live': '正在进行',
  'farms.finished': '已结束',
  'farms.paused': '已暂停',
  'farms.earn': '赚取',
  'farms.approveContract': '授权合约',
  'farms.harvest': '收获',
  'farms.yourEarn': '已赚取',
  'farms.compound': '复利',
  'farms.unstakeType': '赎回{initType}',
  'farms.yourStaked': '已质押',
  'farms.waitForConfirmation': '正在等待确认',
  'farms.detailsHide': '隐藏',
  'farms.detailsOpen': '详情',
  'farms.stakeLPTokens': '质押LP代币',
  'farms.stakeSingle': '质押',
  'farms.cancel': '取消',
  'farms.pendingConfirmation': '正在等待确认…',
  'farms.confirm': '确认',
  'farms.get': '获取',
  'farms.timeframe': '时间表',
  'farms.roi': '投资回报率',
  'farms.calculatedInfo': '按现行汇率计算，每天重算一次。费率只是为方便您而提供的估算，绝不是保证实际回报。',

  // 'pools.depositTokens': '质押 {typeParam} 代币',
  // 'pools.withdrawTokens': '赎回 {typeParam} 代币',
  // 'pools.input': '输入',
  // 'pools.addToMetamask': '添加到Metamask',
  // 'pools.mangoPOOLS': 'OKT池子',
  // 'pools.mangoPOOLSInfo': '质押OKT赚取新的代币。您可以在任何时候赎回代币。奖励按块计算。',

  // 'ifo.applyFor': '申请',
  // 'ifo.yourProject': '您的项目',
  // 'ifo.createIFO': '为您的代币创建一个IFO项目',
  // 'ifo.applyNow': '现在申请',
  // 'ifo.launchTime': '启动时间',
  // 'ifo.forSale': '待售',
  // 'ifo.raiseUSD': '募款目标(USD)',
  // 'ifo.totalRaised': '已募集(%占目标)',
  // 'ifo.totalBurn': '总销毁',
  // 'ifo.launchIFOHint': '也想启动自己的IFO项目？',
  // 'ifo.launchProject': '通过MangoSwap启动您的项目，MangoSwap是Okchain&apos最受欢迎的AMM项目和流动性提供商，将您的代币直接带到Okchain上最活跃和快速增长的社区。',
  // 'ifo.takePartHint': '如何加入',
  // 'ifo.activateProfile': '激活您的个人资料',
  // 'ifo.activatePartIFo': '您需要一个已激活的MangoSwap档案来参加IFO!',
  // 'ifo.stakeLiquidity': 'Stake BNB and ASD in the liquidity pool to get LP tokens.',
  // 'ifo.buySaleTokens': 'You’ll spend them to buy IFO sale tokens.',
  // 'ifo.getLPTokens': 'Get LP tokens',
  // 'ifo.commitLPTokens': '提交LP募资',
  // 'ifo.salesLive': 'When the IFO sales are live, you can “commit” your LP tokens to buy the tokens being sold.',
  // 'ifo.recommendCommit': 'We recommend committing to the Basic Sale first, but you can do both if you want.',
  // 'ifo.claimTokens': 'Claim your tokens and achievement',
  // 'ifo.afterSalesFinish': 'After the IFO sales finish,you can claim any IFO tokens that you bought,and any unspent BNB-ASD LP tokens will be returned to your wallet.',
  // 'ifo.foldableText': '{text}',
  // 'ifo.initialFarm': 'IFO: Initial Farm Offerings',
  // 'ifo.buyTokens': '用全新的代币销售模型购买新代币',
  // 'ifo.nextIfo': '下次 IFO',
  // 'ifo.pastIfos': '往期 IFO',
  // 'ifo.endTime': '结束时间',
  // 'ifo.viewProjectSite': '项目网址',
  // 'ifo.totalCommitted': '总募资/募资进度',
  // 'ifo.end': '结束',
  // 'ifo.untilStart': '开始',
  // 'ifo.YOUR_LP_TOKENS_COMMITTED': '您的LP质押量',
  // 'ifo.TO_RECEIVE': '奖励',
  // 'ifo.ofTotal': '占比',
  // 'ifo.Hide': '隐藏',
  // 'ifo.Detail': '详情',
  // 'ifo.nothingtoClaim': '没有可收割的',
  // 'ifo.overToken': `您将被退还超募的代币`,
  // 'ifo.toReclaim': '可收割',

  // 'question1': 'What’s the difference between a Basic Sale and Unlimited Sale?',
  // 'description1forQ1': 'In the Basic Sale, every user can commit a maximum of about 100 USD worth of CAKE-BNB LP Tokens. We calculate the maximum LP amount about 30 minutes before each IFO. The Basic Sale has no participation fee.',
  // 'description2forQ1': 'In the Unlimited Sale, there’s no limit to the amount of CAKE-BNB LP Tokens you can commit. However, there’s a fee for participation: see below.',
  
  // 'question2': 'Which sale should I commit to? Can I do both?',
  // 'description1forQ2': 'You can choose one or both at the same time! If you’re only committing a small amount, we recommend the Basic Sale first. Just remember you need a MangoSwap Profile in order to participate.',
  
  // 'question3': 'How much is the participation fee?',
  // 'description1forQ3': 'There’s only a participation fee for the Unlimited Sale: there’s no fee for the Basic Sale.',
  // 'description2forQ3': 'The fee will start at 1%.',
  // 'description3forQ3': 'The 1% participation fee decreases in cliffs, based on the percentage of overflow from the “Unlimited” portion of the sale.',
  
  // 'question4': 'Where does the participation fee go?',
  // 'description1forQ4': 'We burn it. The CAKE-BNB LP tokens from the participation fee will be decomposed, then we use the BNB portion to market buy the CAKE equivalent, then finally throw all of the CAKE into the weekly token burn.',
  
  // 'question5': 'How can I get an achievement for participating in the IFO?',
  // 'description1forQ5': 'You need to contribute a minimum of about 10 USD worth of CAKE-BNB LP Tokens to either sale.',
  // 'description2forQ5': 'You can contribute to one or both, it doesn’t matter: only your overall contribution is counted for the achievement.',

  //  // 补充添加
  'supple.transactionSubmitted': '交易已提交',
  'supple.viewOklink': 'Scroll Sepolia Scan查看',
  'supple.close': '关闭',
  'supple.confirmSwap': '确认兑换',
  'supple.priceUpdated': '价格更新',
  'supple.accept': '接受',
  'supple.outputEstimated': '预估的输出量。您将至少收到 ',
  'supple.transactionRevert': ' 否则交易将被撤回',
  'supple.inputEstimated': '预估的输入量，您将最多出售 ',
  'supple.outputSent': '输出结果将被发送到',
  'supple.feePaid': '每笔交易都要支付0.3%的费用。0.15%给到流动性提供者，0.05%流向CoreSwap，0.10%拿去销毁。',
  'supple.confirmTransaction': '在您的钱包中确认此交易',
  'supple.recipient': '接收人',
  'supple.viewBscScan': '(在CoreScan查看)',
  'supple.walletAddress': '钱包地址或ENS姓名',
  'supple.tokenImported': '已导入代币',
  'supple.createHint': '任何人都可以在Corechain上创建和命名任何Erc20令牌，包括创建现有令牌的山寨版本和声称代表没有令牌的项目的令牌。',
  'supple.interfaceHint': '这个接口可以通过代币地址加载任意的代币。在操作任何的KIP20代币时，请格外小心，并做好研究。',
  'supple.purchaseHint': '如果您购买任何一个代币，您都有可能无法将其卖出',
  'supple.IUnderstand': '我明白',
  'supple.continue': '继续',
  'supple.error': '错误',
  'supple.dismiss': '撤销',
}

const ZHCN: Language = { code: 'zhCN', language: '繁體中文', locale, intl: '繁' }
export default ZHCN
