import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" { ...props } xmlns="http://www.w3.org/2000/svg">
        <path d="M2.59821 15.1512L2.5281 15.222L2.59401 15.2878C3.93096 17.1603 5.92767 18.457 8.18231 18.9166C10.4397 19.3768 12.7876 18.9636 14.7523 17.7605C16.717 16.5574 18.1522 14.6539 18.7684 12.434C19.3846 10.2142 19.1359 7.84321 18.0725 5.79952C17.0091 3.75584 15.2101 2.19156 13.0386 1.4223C10.867 0.653034 8.48448 0.736055 6.37172 1.65461C4.25896 2.57316 2.57323 4.25887 1.65465 6.37161C0.744689 8.46454 0.654655 10.8222 1.40084 12.9773L1.36397 13.0125L1.37965 13.07C1.44763 13.3191 1.54224 13.5602 1.66185 13.7891C1.76765 13.9996 1.84089 14.1442 1.88772 14.2362L1.93896 14.3366L1.95114 14.3603L1.95412 14.3661L1.95486 14.3675L1.95506 14.3679L1.95511 14.368L1.95512 14.368L1.95512 14.368L2.04395 14.3221L1.95513 14.368L2.01761 14.4888L2.11429 14.3932L6.32094 10.2302L9.61718 13.5265L9.68791 13.5972L9.75862 13.5264L13.4495 9.83336L15.2263 11.6106L15.3971 11.7814V11.5399V7.04174V6.94174H15.2971H10.8083H10.5667L10.7376 7.11248L12.5167 8.88983L9.68698 11.7221L6.39115 8.42362L6.32264 8.35506L6.25197 8.42139L2.4734 11.9677C1.99481 10.1313 2.20362 8.1809 3.06408 6.48512C3.9495 4.74016 5.4599 3.3929 7.29428 2.71181C9.12866 2.03072 11.1523 2.06582 12.962 2.81012C14.7716 3.55441 16.2344 4.95325 17.0588 6.72786C17.8831 8.50248 18.0086 10.5226 17.4101 12.3855C16.8116 14.2485 15.5331 15.8175 13.8294 16.78C12.1258 17.7424 10.122 18.0276 8.21744 17.5787C6.31289 17.1298 4.64742 15.9797 3.55293 14.3577L3.48478 14.2567L3.39901 14.3433L2.59821 15.1512Z" fill="white" stroke="white" strokeWidth="0.2"/>
    </Svg>
  );
};

export default Icon;
