import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="2.85" cy="2.85" r="2.1" stroke="#202251" strokeWidth="1.5"/>
<circle cx="9.4999" cy="2.85" r="2.1" stroke="#202251" strokeWidth="1.5"/>
<circle cx="16.1498" cy="2.85" r="2.1" stroke="#202251" strokeWidth="1.5"/>
<circle cx="2.85" cy="16.15" r="2.1" stroke="#202251" strokeWidth="1.5"/>
<circle cx="9.4999" cy="16.15" r="2.1" stroke="#202251" strokeWidth="1.5"/>
<circle cx="2.85" cy="9.50002" r="2.1" stroke="#202251" strokeWidth="1.5"/>
<circle cx="9.4999" cy="9.50002" r="2.1" stroke="#202251" strokeWidth="1.5"/>
<circle cx="16.1498" cy="9.50002" r="2.1" stroke="#202251" strokeWidth="1.5"/>
</svg>




  );
};

export default Icon;
