import React, { useState } from "react";
import useTheme from 'hooks/useTheme'
import Button from "../../components/Button/Button";
import Flex from "../../components/Box/Flex";
import { connectorLocalStorageKey } from "./config";

interface Props {
  logout: () => void;
  onDismiss?: () => void;
}


const Logout: React.FC<Props> = ({ logout, onDismiss = () => null }) => {
  const { isDark, toggleTheme } = useTheme()
  return (
    <Flex justifyContent="center">
      <Button
        style={{color:isDark?'#FFFFFF':'#000000'}}
        scale="sm"
        variant="secondary"
        onClick={() => {
          logout();
          window.localStorage.removeItem(connectorLocalStorageKey);
          onDismiss();
        }}
      >
        Logout
      </Button>
    </Flex>
  );
};

export default Logout;
