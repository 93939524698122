import { ChainId } from 'openfi-new-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  // [ChainId.MAINNET]: '0x74780EfE30E584FA946898314C5c15390F6FF310',
  // [ChainId.BNB]: '0xBd09DF438AccA57476BcFe70A333B3204DB4C6e7',
  [ChainId.MAINNET]: '0x9914A0385F077f92a807bE64fD01Cf1E9335721d',
  // [ChainId.MAINNET]: '0x6aE6028A54E4E21dECaA58CB4B754e7aa329A9D5'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS } 
 