import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6898 15.39C12.4798 15.39 12.2698 15.32 12.0998 15.18C11.9098 15.02 11.7798 14.8 11.7598 14.55C11.7298 14.3 11.8098 14.06 11.9598 13.86L12.8898 12.71H5.19977C4.67977 12.71 4.25977 12.29 4.25977 11.77C4.25977 11.25 4.67977 10.83 5.19977 10.83H14.8498C15.2098 10.83 15.5398 11.04 15.6998 11.36C15.8598 11.68 15.8098 12.08 15.5798 12.36L13.4198 15.04C13.2398 15.26 12.9798 15.39 12.6898 15.39ZM5.19977 8.50999C4.83977 8.50999 4.50977 8.29999 4.34977 7.96999C4.19977 7.63999 4.23977 7.24999 4.46977 6.96999L6.62977 4.32999C6.80977 4.10999 7.06977 3.98999 7.35977 3.98999C7.57977 3.98999 7.78977 4.06999 7.94977 4.19999C8.34977 4.52999 8.40977 5.11999 8.07977 5.51999L7.17977 6.61999H14.8498C15.3698 6.61999 15.7898 7.03999 15.7898 7.55999C15.7898 8.07999 15.3698 8.49999 14.8498 8.49999H5.19977V8.50999Z" fill="white"/>
    <path d="M10 20C4.49 20 0 15.51 0 10C0 4.49 4.49 0 10 0C15.51 0 20 4.49 20 10C20 15.51 15.51 20 10 20ZM10 1.88C5.52 1.88 1.88 5.52 1.88 10C1.88 14.48 5.52 18.12 10 18.12C14.48 18.12 18.12 14.48 18.12 10C18.12 5.52 14.48 1.88 10 1.88Z" fill="white"/>
    </svg>
    

  );
};

export default Icon;
