export default {
    'global.year': '年',
    'global.zhCN': '繁體中文',
    'global.close': '關閉',
    'global.approve': '同意',
    'global.totalStaked': '所有質押',
    'global.stake': '質押',
    'global.unstake': '解壓',
    'global.coming': '敬请期待'
  }
  