import React from "react";
import styled from "styled-components";
import ths_light from 'assets/THS_light@2x.png'
import ths_dark from 'assets/THS_dark@2x.png'
import { useLocation } from "react-router-dom";
import useIntl from 'hooks/useIntl'
import { SvgProps } from "../../../components/Svg";
import * as IconModule from "../icons";
import Accordion from "./Accordion";
import { MenuEntry, LinkLabel, LinkStatus } from "./MenuEntry";
import MenuLink from "./MenuLink";
import { PanelProps, PushedProps } from "../types";


interface Props extends PanelProps, PushedProps {
  isMobile: boolean;
}

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const Container = styled.div`
  // border:1px solid red;
  // width:280px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  position: relative;
`;

export const MenuTag = styled.div`
  background: #ffecce;
  font-size: 12px;
  padding: 4px;
  border-radius: 5px;
  color: #ffa307;
`

const LogoContainer = styled.div`
  width: 100%;
  height: 155px;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  .logoBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .logoOverview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .logoTitle {
        margin-top: 5px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        text-align: center;
      }
    }
    .logoText {
      color: #A0A0A0;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 419.02%;
    }
  }
`

const PanelBody: React.FC<Props> = ({ isPushed, pushNav, isMobile, links, logo, logoText,isDark }) => {
  const location = useLocation();
  const intl = useIntl()
  // Close the menu when a user clicks a link on mobile
  const handleClick = isMobile ? () => pushNav(false) : undefined;
  const LogoIcon: any = logo;
  console.log("links", links);
  return (
    <Container >
      { isPushed && 
        <LogoContainer style={{ marginTop: isMobile ? '0px' : '0px', marginBottom: isMobile ? '0px' : '50px' }}>
          <div className="logoBox">
            <div className="logoOverview">
              {/* <LogoIcon/> */}
              {
                isDark ?
              (<img src={ths_dark} alt="dark_logo" width="100px" height="72px"/>)
              :
              (<img src={ths_light}  alt="light_logo" width="100px" height="72px"/>)
              }
              <span className="logoTitle">OPENFI</span>
            </div>
            { !isMobile ? <div className="logoText">{ logoText }</div> : null }
          </div>
        </LogoContainer>
      }
      
      {links.map((entry) => {
        const Icon = isDark ? Icons[entry.icond] : Icons[entry.iconL];
        const iconElement = <Icon width="32px" mr="12px" />;
        const calloutClass = entry.calloutClass ? entry.calloutClass : undefined;

        if (entry.items) {
          const itemsMatchIndex = entry.items.findIndex((item) => item.href === location.pathname);
          const initialOpenState = entry.initialOpenState === true ? entry.initialOpenState : itemsMatchIndex >= 0;
          return (
            <Accordion
              key={entry.label}
              comming={entry.comming}
              isPushed={isPushed}
              pushNav={pushNav}
              icon={iconElement}
              label={entry.label}
              status={entry.status}
              initialOpenState={initialOpenState}
              className={calloutClass}
              isActive={entry.items.some((item) => item.href === location?.pathname)}
              togglePush={() => { let i; i++}}
            >
              {isPushed &&
                entry.items.map((item,key) => (
                  <MenuEntry key={item.label} secondary isActive={item.href === location?.pathname} onClick={handleClick}>
                    <MenuLink target={item.target} href={item?.href}>
                      <LinkLabel  isActive={item.href === location?.pathname} isPushed={isPushed} style={{paddingLeft: '19px'}}>{item.label}</LinkLabel>
                      {item.status && (
                        <LinkStatus color={item.status.color} fontSize="14px">
                          {item.status.text}
                        </LinkStatus>
                      )}
                    </MenuLink>
                  </MenuEntry>
                ))}
            </Accordion>
          );
        }
        return (
          <MenuEntry key={entry.label} isActive={entry.href === location?.pathname || location?.pathname?.includes(entry.href)} className={calloutClass}>
            <MenuLink href={entry.href} onClick={handleClick}>
              {iconElement}
              <LinkLabel isActive={entry.href === location?.pathname} isPushed={isPushed}>{entry.label}</LinkLabel>
              {
                entry.comming && <MenuTag>
                  { intl(`global.coming`) }
                </MenuTag>
              }
              {entry.status && (
                <LinkStatus color={entry.status.color} fontSize="14px">
                  {entry.status.text}
                </LinkStatus>
              )}
            </MenuLink>
          </MenuEntry>
        );
      })}
    </Container>
  );
};

export default PanelBody;
