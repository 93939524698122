import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="16" viewBox="0 0 20 16" fill="none" { ...props } xmlns="http://www.w3.org/2000/svg">
        <path d="M11.02 15.6009C10.8864 15.7274 10.7274 15.8277 10.5524 15.8962C10.3773 15.9647 10.1896 16 10 16C9.81041 16 9.62269 15.9647 9.44763 15.8962C9.27258 15.8277 9.11365 15.7274 8.97998 15.6009L0.422477 7.55054C0.288344 7.42466 0.181972 7.27508 0.10947 7.11041C0.0369686 6.94574 -0.000233564 6.76921 1.1033e-06 6.59097C1.1033e-06 6.24284 0.140477 5.8957 0.422477 5.63041L5.98281 0.399618C6.11649 0.272982 6.27549 0.172477 6.45064 0.103893C6.62579 0.0353089 6.81364 0 7.00336 0C7.19307 0 7.38092 0.0353089 7.55607 0.103893C7.73122 0.172477 7.89022 0.272982 8.0239 0.399618L9.99895 2.25959L11.9761 0.399618C12.2591 0.135317 12.6271 0.00218002 12.9972 0.00218002C13.3662 0.00218002 13.7352 0.135317 14.0172 0.399618L19.5775 5.6314C19.8595 5.89767 20 6.24383 20 6.59196C20 6.9391 19.8595 7.28624 19.5775 7.55153L11.02 15.6009ZM13.3368 11.6807L14.2111 10.8592L12.3304 9.09L13.2551 8.22017L15.1358 9.98941L15.7511 9.41051L13.08 6.89768L12.9018 7.06533C12.639 7.31285 12.3269 7.50909 11.9832 7.64279C11.6396 7.77649 11.2713 7.84502 10.8995 7.84443C10.5276 7.84502 10.1593 7.77649 9.81569 7.64279C9.47208 7.50909 9.15992 7.31285 8.89716 7.06533L7.91488 6.14126C7.78074 6.01538 7.67437 5.86581 7.60187 5.70113C7.52937 5.53646 7.49217 5.35993 7.4924 5.18169C7.4924 4.83455 7.63392 4.48642 7.91488 4.22212L9.07433 3.1304L7.00283 1.18167L1.25275 6.58998L10 14.8208L10.7097 14.1531L8.82902 12.3829L9.75469 11.5131L11.6354 13.2823L12.4133 12.5506L10.5326 10.7813L11.4572 9.91051L13.3379 11.6807H13.3368ZM18.7472 6.59196L12.9982 1.18069L8.7441 5.18169L9.82178 6.1955C10.1195 6.4746 10.5084 6.61464 10.8984 6.61464C11.2884 6.61464 11.6784 6.47558 11.9761 6.1955L12.0589 6.11661L13.1743 5.06631L13.2949 4.95191L16.8928 8.33556L18.7483 6.59097L18.7472 6.59196Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
