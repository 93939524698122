import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 4.4C7.35492 4.4 4.4 7.35492 4.4 11C4.4 14.6451 7.35492 17.6 11 17.6C14.6451 17.6 17.6 14.6451 17.6 11C17.6 7.35492 14.6451 4.4 11 4.4ZM11 19.0667C10.595 19.0667 10.2667 19.395 10.2667 19.8V21.2667C10.2667 21.6717 10.595 22 11 22C11.405 22 11.7333 21.6717 11.7333 21.2667V19.8C11.7333 19.395 11.405 19.0667 11 19.0667ZM16.704 16.704C16.4176 16.9904 16.4176 17.4547 16.704 17.7411L17.7411 18.7782C18.0275 19.0646 18.4918 19.0646 18.7782 18.7782C19.0646 18.4918 19.0646 18.0275 18.7782 17.7411L17.7411 16.704C17.4547 16.4176 16.9904 16.4176 16.704 16.704ZM4.25892 16.704L3.22183 17.7411C2.93544 18.0275 2.93544 18.4918 3.22183 18.7782C3.50822 19.0646 3.97253 19.0646 4.25892 18.7782L5.296 17.7411C5.58239 17.4547 5.58239 16.9904 5.296 16.704C5.00963 16.4176 4.54529 16.4176 4.25892 16.704ZM19.8 10.2667C19.395 10.2667 19.0667 10.595 19.0667 11C19.0667 11.405 19.395 11.7333 19.8 11.7333H21.2667C21.6717 11.7333 22 11.405 22 11C22 10.595 21.6717 10.2667 21.2667 10.2667H19.8ZM0.733334 10.2667C0.328327 10.2667 0 10.595 0 11C0 11.405 0.328327 11.7333 0.733334 11.7333H2.2C2.60501 11.7333 2.93333 11.405 2.93333 11C2.93333 10.595 2.60501 10.2667 2.2 10.2667H0.733334ZM17.7411 3.22183L16.704 4.25892C16.4176 4.54529 16.4176 5.00963 16.704 5.296C16.9904 5.58239 17.4547 5.58239 17.7411 5.296L18.7782 4.25892C19.0646 3.97254 19.0646 3.50822 18.7782 3.22183C18.4918 2.93544 18.0275 2.93544 17.7411 3.22183ZM3.22183 3.22183C2.93544 3.50822 2.93544 3.97254 3.22183 4.25892L4.25892 5.296C4.54529 5.58239 5.00963 5.58239 5.296 5.296C5.58239 5.00963 5.58239 4.54529 5.296 4.25892L4.25892 3.22183C3.97253 2.93544 3.50822 2.93544 3.22183 3.22183ZM11 0C10.595 0 10.2667 0.328327 10.2667 0.733333V2.2C10.2667 2.60501 10.595 2.93333 11 2.93333C11.405 2.93333 11.7333 2.60501 11.7333 2.2V0.733333C11.7333 0.328327 11.405 0 11 0Z" fill="#0A0E13"/>
</svg>



  );
};

export default Icon;
