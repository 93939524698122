import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 2.66667C12.1256 2.66667 13.2169 2.88687 14.2431 3.32083C15.2352 3.74042 16.1267 4.34167 16.8925 5.1075C17.6583 5.87333 18.2594 6.76479 18.6792 7.75688C19.1133 8.78313 19.3333 9.87438 19.3333 11C19.3333 12.1256 19.1131 13.2169 18.6792 14.2431C18.2596 15.2352 17.6585 16.1267 16.8925 16.8925C16.1267 17.6583 15.2352 18.2594 14.2431 18.6792C13.2169 19.1133 12.1256 19.3333 11 19.3333C9.87438 19.3333 8.78313 19.1131 7.75688 18.6792C6.76479 18.2596 5.87333 17.6585 5.1075 16.8925C4.34167 16.1267 3.74063 15.2352 3.32083 14.2431C2.88667 13.2169 2.66667 12.1256 2.66667 11C2.66667 9.87438 2.88688 8.78313 3.32083 7.75688C3.74042 6.76479 4.34167 5.87333 5.1075 5.1075C5.87333 4.34167 6.76479 3.74062 7.75688 3.32083C8.78313 2.88667 9.87417 2.66667 11 2.66667ZM11 1C5.47708 1 1 5.47708 1 11C1 16.5229 5.47708 21 11 21C16.5229 21 21 16.5229 21 11C21 5.47708 16.5227 1 11 1Z" fill="#253449" stroke="#253449" strokeWidth="0.3"/>
    <path d="M13.4749 11.0932L13.4734 11.0915C13.1757 10.7507 12.7573 10.5078 12.2301 10.3696L10.2837 9.88205C10.0907 9.83194 9.94408 9.76102 9.84765 9.67056L9.84609 9.66907C9.78466 9.61238 9.70282 9.50706 9.70282 9.24928C9.70282 8.86963 9.8321 8.61631 10.1087 8.45238C10.3241 8.32626 10.6128 8.26234 10.9669 8.26234C11.3613 8.26234 11.6899 8.35598 11.9434 8.5405L11.9451 8.54178C12.2307 8.74626 12.3637 9.03695 12.3637 9.45652C12.3637 9.63234 12.4943 9.77503 12.6553 9.77503H13.5185C13.6794 9.77503 13.8101 9.63234 13.8101 9.45652C13.8101 8.7762 13.6409 8.20629 13.3085 7.76378C12.9572 7.29111 12.4375 6.98492 11.7581 6.84797L11.7544 5.28029C11.754 5.12507 11.6385 4.99958 11.4964 5L10.4556 5.00297C10.3134 5.0034 10.1986 5.12953 10.1989 5.28474L10.2026 6.82695C9.65732 6.93545 9.20727 7.18664 8.85948 7.57819C8.44014 8.05277 8.22746 8.66111 8.22746 9.38645C8.22746 10.054 8.45336 10.5853 8.89991 10.9662C9.15284 11.1799 9.48994 11.3425 9.90189 11.4495L11.2981 11.7992C11.9504 11.9655 12.2214 12.1188 12.3337 12.2182C12.4622 12.3318 12.5246 12.5233 12.5246 12.804C12.5246 13.0756 12.4356 13.2739 12.2453 13.4272C11.9879 13.6336 11.58 13.7381 11.033 13.7381C10.5705 13.7381 10.1984 13.6349 9.92697 13.4315C9.60347 13.1886 9.44639 12.8238 9.44639 12.3159C9.44639 12.14 9.31575 11.9973 9.15478 11.9973H8.29161C8.13064 11.9973 8 12.14 8 12.3159C8 12.7373 8.05502 13.1217 8.1635 13.4576C8.27723 13.8107 8.48757 14.1405 8.78755 14.4367C9.02706 14.6753 9.2829 14.8558 9.54846 14.9732C9.74617 15.0603 9.97149 15.1259 10.2227 15.1697L10.2264 16.7197C10.2267 16.8749 10.3422 17.0004 10.4843 17L11.5252 16.997C11.6673 16.9966 11.7822 16.8705 11.7818 16.7153L11.7781 15.1745C12.227 15.0975 12.6255 14.9467 12.9679 14.7225C13.6528 14.2689 14 13.5776 14 12.6681C14 12.0281 13.8233 11.4981 13.4749 11.0932Z" fill="#253449"/>
    </svg>
    


  );
};

export default Icon;
